import { lazy } from 'react'

import { $t } from 'packages/locale'
import { ModalRouteNode } from 'packages/route'

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'AdminExamNewModal',
    getTitle: () => $t('创建考试'),
    component: lazy(() =>
      import('../pages/admin/Edit').then(m => ({
        default: m.AdminExamNewModal,
      })),
    ),
  },
  {
    name: 'AdminExamEditModal',
    getTitle: () => $t('编辑考试'),
    component: lazy(() =>
      import('../pages/admin/Edit').then(m => ({
        default: m.AdminExamEditModal,
      })),
    ),
  },
  {
    name: 'AdminExamEditKnowledgePointModal',
    getTitle: () => $t('编辑知识点'),
    component: lazy(() =>
      import('../pages/admin/KnowledgePointModal').then(m => ({
        default: m.KnowledgePointModal,
      })),
    ),
  },
  {
    name: 'StudentExamAnalysisReportModal',
    getTitle: () => $t('学生考试报告单'),
    component: lazy(() =>
      import('../pages/student/AnalysisReport').then(m => ({
        default: m.StudentExamAnalysisReportModal,
      })),
    ),
  },
  {
    name: 'StudentExamAnalysisReportDetailsModal',
    getTitle: () => $t('学生考试报告单'),
    component: lazy(() =>
      import('../pages/student/AnalysisDetailsEntry').then(m => ({
        default: m.AnalysisDetailsEntry,
      })),
    ),
  },
]
