/**
 * @file exam menus
 */

import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  hasEnabledPluginAtom,
  ModuleEnum,
  ScopeDomain,
} from 'packages/feature-utils/plugins'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
  roleApi$queryManagedScopes,
  RoleEnum,
  ScopeEnhancerEnum,
} from 'packages/sdks-next/chalk'

import { getStoreForAtom } from '@/store'

export const adminExamMenu = loadable(
  atom(async get => {
    if (!get(hasEnabledPluginAtom(ModuleEnum.Exam))) {
      return []
    }

    const store = getStoreForAtom(get)
    const me = store.session.currentReflection

    if (!me) return []
    const { data: scopes } = await roleApi$queryManagedScopes.api(
      me.id,
      ScopeDomain.Exam,
      {
        type: ScopeEnhancerEnum.ExamScope,
      },
    )

    if (me.role !== RoleEnum.Shadow && !scopes.length) return []

    const hasAllExamPermission =
      me.role === RoleEnum.Shadow ||
      scopes.some(scope => {
        const params = scope.params as {
          graduatesInIdIn?: string
          managedScope?: boolean
        }

        // 届别范围没有设置，且当前查询的 scope 不是被管理的子管理权限，则有全部的考试管理的权限
        return !params.graduatesInIdIn && !params.managedScope
      })

    return [
      {
        prefix: [PluginCategoryEnum.教务教学],
        name: SchoolModulesEnum.Exam,
        path: '/admin/exams',
        label: $t('考试管理'),
        type: MenuType.AdminApps,
        icon: 'Examination' as const,
        shortcutIcon: 'ExamSolid+EA7B25',
        sort: 705,
        permission: [PermissionNameEnum.CoreExamWrite],
        subMenus: hasAllExamPermission
          ? [
              {
                label: $t('全部考试'),
                path: '/admin/exams',
                icon: 'Examination' as const,
              },
              {
                label: $t('全局设置'),
                path: '/admin/exams/settings',
                icon: 'Setting' as const,
              },
            ]
          : [
              {
                label: $t('我管理的'),
                path: '/admin/exams',
                icon: 'Examination' as const,
              },
            ],
      },
    ]
  }),
)
