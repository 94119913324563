import { getAdminRoutes } from './admin'
import { getUserRoutes } from './user'

/**
 * 考试路由
 *
 * @returns routes
 */
export const getRoutes = () => [...getAdminRoutes(), ...getUserRoutes()]

export * from './modal'
