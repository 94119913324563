export enum SubjectAnalysisTabKeyEnum {
  /**
   * 科目概况
   */
  SubjectOverview = 'subject-overview',
  /**
   * 成绩分布
   */
  Distribution = 'people-distribution',
  /**
   * 档位对比
   */
  IndicatorComparison = 'indicator-comparison',
  /**
   * 排名统计
   */
  RankingStatistics = 'ranking-statistics',
  /**
   * 教师对比
   */
  TeacherComparison = 'teacher-comparison',
  /**
   * 课程对比
   */
  CourseComparison = 'course-comparison',
  /**
   * 题目概况
   */
  QuestionOverview = 'question-overview',
  /**
   * 题目得分情况
   */
  QuestionCondition = 'question-condition',
  /**
   * 题目作答分布
   */
  QuestionDistribution = 'question-distribution',
  /**
   * 知识点得分率
   */
  KnowledgeScoreRate = 'knowledge-score-rate',
  /**
   * 学生小分表
   */
  StudentScoreTable = 'student-score-table',
  /**
   * 优秀答卷
   */
  StudentBlockMarksTypical = 'student-block-marks-typical',
  /**
   * 典型错误
   */
  StudentBlockMarksProblem = 'student-block-marks-problem',
}

export type ExamSubjectAnalysisTabItemType = {
  label: string
  key: SubjectAnalysisTabKeyEnum | string
  isSub?: boolean
  isDivider?: boolean
}
