import { flatten } from '@seiue/util'
import { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { getRouteSlots } from '@/plugins'
import { RouteMenus } from '@/router'

const getUserAnalysisSubRoutes: () => RouteConfig[] = () => {
  return [
    {
      path: 'total-score',
      getTitle: () => $t('成绩总表'),
      component: lazy(() =>
        import('../components/Analysis/TotalScore').then(m => ({
          default: m.ExamTotalScoreAnalysis,
        })),
      ),
    },
    {
      path: 'overview',
      getTitle: () => $t('整体分析'),
      component: lazy(() =>
        import('../components/Analysis/Overview').then(m => ({
          default: m.ExamOverviewAnalysis,
        })),
      ),
    },
    {
      path: 'subjects',
      name: 'Subjects',
      getTitle: () => $t('科目分析'),
      component: lazy(() =>
        import('../components/Analysis/Subjects').then(m => ({
          default: m.ExamSubjectsAnalysis,
        })),
      ),
    },
    {
      path: 'admin-classes',
      name: 'AdminClasses',
      getTitle: () => $t('班级分析'),
      component: lazy(() =>
        import('../components/Analysis/AdminClasses').then(m => ({
          default: m.ExamAdminClassesAnalysis,
        })),
      ),
    },
    {
      path: 'custom-dimensions/:fieldName',
      name: 'CustomDimensions',
      getTitle: () => $t('自定义维度分析'),
      component: lazy(() =>
        import('../components/Analysis/CustomDimension').then(m => ({
          default: m.CustomDimensionAnalysis,
        })),
      ),
    },
  ]
}

/**
 * 考试前台路由
 *
 * @returns routes
 */
export const getUserRoutes: () => RouteConfig[] = () => [
  /**
   * 抄送人考试分析
   */
  {
    path: 'teacher/exams/exam-list/frontend/managed/:id/analysis',
    name: 'TeacherExamAnalysis',
    module: [PluginEnum.ExamsFrontend],
    getTitle: () => $t('考试分析'),
    component: lazy(() =>
      import('../pages/teacher/ReceiverAnalysis').then(m => ({
        default: m.ReceiverExamAnalysis,
      })),
    ),
    subRoutes: getUserAnalysisSubRoutes(),
  },
  {
    path: 'teacher/exams/exam-list/frontend/managed/:id/analysis/:policy/subjects/:subjectId',
    name: 'TeacherExamAnalysis.Subject',
    getTitle: () => $t('科目详情'),
    component: lazy(() =>
      import('../pages/admin/Analysis/Subject').then(m => ({
        default: m.AdminExamSubjectAnalysis,
      })),
    ),
  },
  {
    path: 'teacher/exams/exam-list/frontend/managed/:id/analysis/admin-classes/:adminClassId',
    name: 'TeacherExamAnalysis.AdminClass',
    getTitle: () => $t('班级详情'),
    component: lazy(() =>
      import('../pages/admin/AdminClassAnalysis').then(m => ({
        default: m.AdminExamAdminClassAnalysis,
      })),
    ),
  },
  {
    path: 'student/exams/exam-list/:id/report',
    name: 'StudentExamAnalysis.Report',
    getTitle: () => $t('考试成绩报告'),
    component: lazy(() =>
      import('../pages/student/ReportDetails').then(m => ({
        default: m.ReportDetails,
      })),
    ),
  },
  {
    path: 'teacher/exams/exam-list/:id/report',
    name: 'AdminStudentExamAnalysis.Report',
    getTitle: () => $t('考试成绩报告'),
    component: lazy(() =>
      import('../pages/student/ReportDetails').then(m => ({
        default: m.ReportDetails,
      })),
    ),
  },
  /**
   * 班主任考试分析
   */
  {
    path: 'teacher/exams/:id/admin-classes/:adminClassId/analysis',
    name: 'TeacherAdminClassExamAnalysis',
    getTitle: () => $t('考试分析'),
    component: lazy(() =>
      import('../pages/teacher/AdminClassAnalysis').then(m => ({
        default: m.TeacherAdminClassExamAnalysis,
      })),
    ),
    subRoutes: [
      {
        path: 'total-score',
        getTitle: () => $t('成绩总表'),
        component: lazy(() =>
          import('../components/Analysis/TotalScore').then(m => ({
            default: m.ExamTotalScoreAnalysis,
          })),
        ),
      },
      {
        path: 'admin-class',
        name: 'AdminClass',
        getTitle: () => $t('班级分析'),
        component: lazy(() =>
          import('../components/Analysis/AdminClass').then(m => ({
            default: m.ExamAdminClassAnalysis,
          })),
        ),
      },
    ],
  },
  {
    path: 'user/exams/interpage',
    getTitle: () => $t('考试'),
    component: lazy(() =>
      import('../components/ExamListInterpage').then(m => ({
        default: m.ExamListInterpage,
      })),
    ),
    module: [PluginEnum.ExamsFrontend],
  },
  {
    path: 'teacher/exams',
    name: 'TeacherExamsFrontend',
    getTitle: () => $t('考试'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    module: [PluginEnum.ExamsFrontend],
    subRoutes: [
      {
        path: 'exam-list',
        getTitle: () => $t('考试分析'),
        component: lazy(() =>
          import('../pages/teacher/ExamAnalysisEntry').then(m => ({
            default: m.ExamAnalysisEntry,
          })),
        ),
      },
      ...flatten(getRouteSlots('examFrontendSubRoutes')),
    ],
  },
  {
    path: 'teacher/exams/exam-list/frontend/classes/:examId/analysis/:subjectId',
    name: 'TeacherFrontendClassExamsAnalysis',
    getTitle: () => $t('科目详情'),
    component: lazy(() =>
      import('@/features/teacher-profiles/pages/SubjectAnalysis').then(m => ({
        default: m.SubjectAnalysis,
      })),
    ),
  },
  {
    path: 'teacher/exams/exam-list/frontend/admin-classes/:examId/analysis',
    name: 'TeacherFrontendAdminClassExamsAnalysis',
    getTitle: () => $t('考试分析'),
    component: lazy(() =>
      import('../pages/teacher/AdminClassAnalysis').then(m => ({
        default: m.TeacherAdminClassExamAnalysisWithTabs,
      })),
    ),
    subRoutes: [
      {
        path: 'total-score',
        getTitle: () => $t('成绩总表'),
        component: lazy(() =>
          import('../components/Analysis/TotalScore').then(m => ({
            default: m.ExamTotalScoreAnalysis,
          })),
        ),
      },
      {
        path: 'admin-class',
        name: 'AdminClass',
        getTitle: () => $t('班级分析'),
        component: lazy(() =>
          import('../components/Analysis/AdminClass').then(m => ({
            default: m.ExamAdminClassAnalysis,
          })),
        ),
      },
    ],
  },
  ...flatten(getRouteSlots('examFrontendRoutes')),
  {
    path: 'student/exams',
    name: 'StudentExamsFrontend',
    getTitle: () => $t('考试'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    module: [PluginEnum.ExamsFrontend],
    subRoutes: [
      {
        path: 'exam-list',
        name: 'StudentExamList',
        getTitle: () => $t('考试列表'),
        component: lazy(() =>
          import('../pages/student/ExamList').then(m => ({
            default: m.ExamList,
          })),
        ),
      },
    ],
  },
]
