import { flatten } from '@seiue/util'
import { lazy } from 'react'

import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
} from 'packages/sdks-next/chalk'

import { getRouteSlots } from '@/plugins'
import { RouteMenus } from '@/router'

const getAdminScoreSubRoutes: () => RouteConfig[] = () => [
  {
    path: 'entry',
    name: 'AdminExamScoresEntry',
    getTitle: () => $t('总成绩录入'),
    component: lazy(() =>
      import('../pages/admin/Entry').then(m => ({
        default: m.AdminExamsEntry,
      })),
    ),
  },
  ...flatten(getRouteSlots('adminExamScoresSubRoutes')),
  {
    path: 'analysis',
    name: 'AdminExamScoresAnalysis',
    getTitle: () => $t('成绩分析'),
    component: lazy(() =>
      import('../pages/admin/Analysis').then(m => ({
        default: m.AdminExamAnalysis,
      })),
    ),
    subRoutes: [
      {
        path: 'total-score',
        getTitle: () => $t('成绩总表'),
        component: lazy(() =>
          import('../components/Analysis/TotalScore').then(m => ({
            default: m.ExamTotalScoreAnalysis,
          })),
        ),
      },
      {
        path: 'overview',
        getTitle: () => $t('整体分析'),
        component: lazy(() =>
          import('../components/Analysis/Overview').then(m => ({
            default: m.ExamOverviewAnalysis,
          })),
        ),
      },
      {
        path: 'subjects',
        getTitle: () => $t('科目分析'),
        component: lazy(() =>
          import('../components/Analysis/Subjects').then(m => ({
            default: m.ExamSubjectsAnalysis,
          })),
        ),
      },
      {
        path: 'admin-classes',
        name: 'AdminClasses',
        getTitle: () => $t('班级分析'),
        component: lazy(() =>
          import('../components/Analysis/AdminClasses').then(m => ({
            default: m.ExamAdminClassesAnalysis,
          })),
        ),
      },
      {
        path: 'custom-dimensions/:fieldName',
        name: 'CustomDimensions',
        getTitle: () => $t('自定义维度分析'),
        component: lazy(() =>
          import('../components/Analysis/CustomDimension').then(m => ({
            default: m.CustomDimensionAnalysis,
          })),
        ),
      },
    ],
  },
  {
    path: 'settings',
    getTitle: () => $t('设置'),
    component: lazy(() =>
      import('../pages/admin/ExamSettings').then(m => ({
        default: m.AdminExamSettings,
      })),
    ),
  },
]

/**
 * 考试管理界面路由
 *
 * @returns - routes
 */
export const getAdminRoutes: () => RouteConfig[] = () => [
  {
    path: 'admin/exams',
    name: 'AdminExams',
    getTitle: () => $t('考试管理'),
    module: [PluginCategoryEnum.教务教学, SchoolModulesEnum.Exam],
    requirePermissions: [PermissionNameEnum.CoreExamWrite],
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'list',
        getTitle: () => $t('全部考试'),
        component: lazy(() =>
          import('../pages/admin/List').then(m => ({
            default: m.AdminExamList,
          })),
        ),
      },
      {
        path: 'settings',
        getTitle: () => $t('全局设置'),
        component: lazy(() =>
          import('../pages/admin/Settings').then(m => ({
            default: m.AdminExamGlobalSettings,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/exams/settings/roles/new',
    name: 'AdminExamSettingNewRole',
    getTitle: () => $t('权限设置'),
    component: lazy(() =>
      import('../pages/admin/Settings/PermissionSetting/NewRole').then(m => ({
        default: m.NewRole,
      })),
    ),
  },
  {
    path: 'admin/exams/settings/roles/:roleId/edit',
    name: 'AdminExamSettingEditRole',
    getTitle: () => $t('权限设置'),
    component: lazy(() =>
      import('../pages/admin/Settings/PermissionSetting/NewRole').then(m => ({
        default: m.NewRole,
      })),
    ),
  },
  {
    path: 'admin/exams/:id/view',
    name: 'AdminExamView',
    getTitle: () => $t('考试详情'),
    component: lazy(() =>
      import('../pages/admin/View').then(m => ({
        default: m.AdminExamsView,
      })),
    ),
    subRoutes: [
      {
        path: 'base',
        getTitle: () => $t('基本信息'),
        component: lazy(() =>
          import('../pages/admin/View/Base').then(m => ({
            default: m.AdminExamsView,
          })),
        ),
      },
      {
        path: 'logs',
        getTitle: () => $t('信息修改日志'),
        component: lazy(() =>
          import('../pages/admin/Logs').then(m => ({
            default: m.AdminExamsLogs,
          })),
        ),
      },
    ],
  },
  {
    path: 'admin/exams/:id/scores',
    getTitle: () => $t('考试成绩'),
    component: lazy(() =>
      import('../pages/admin/Scores').then(m => ({
        default: m.AdminExamsScores,
      })),
    ),
    subRoutes: getAdminScoreSubRoutes(),
  },
  {
    path: 'admin/exams/:id/scores/analysis/:policy/subjects/:subjectId',
    name: 'AdminExamScoresAnalysis.Subject',
    getTitle: () => $t('科目详情'),
    component: lazy(() =>
      import('../pages/admin/Analysis/Subject').then(m => ({
        default: m.AdminExamSubjectAnalysis,
      })),
    ),
  },
  {
    path: 'admin/exams/:id/scores/analysis/admin-classes/:adminClassId',
    name: 'AdminExamScoresAnalysis.AdminClass',
    getTitle: () => $t('班级详情'),
    component: lazy(() =>
      import('../pages/admin/AdminClassAnalysis').then(m => ({
        default: m.AdminExamAdminClassAnalysis,
      })),
    ),
  },
  {
    path: 'admin/exams/:id/scores/analysis/custom-dimensions/details/:fieldName/:dimId',
    name: 'AdminExamScoresAnalysis.CustomDimensionDetails',
    getTitle: () => $t('自定义维度详情'),
    component: lazy(() =>
      import('../pages/admin/CustomDimensionAnalysis').then(m => ({
        default: m.CustomDimensionAnalysis,
      })),
    ),
  },
]
