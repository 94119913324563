/* eslint-disable max-lines-per-function */
import { flatten } from '@seiue/util'
import { lazy } from 'react'

import { ModuleEnum } from 'packages/feature-utils/plugins'
import { StudentProfileSubRoute } from 'packages/feature-utils/profiles'
import { GroupPermissionTypeEnum } from 'packages/features/groups/types'
import { SchoolModulesEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { getRouteSlots } from '@/plugins/slot'

/**
 * 档案子路由
 *
 * @returns 路由
 */
export const getProfileSubRoutes = (): RouteConfig[] => [
  {
    path: 'home',
    getTitle: () => $t('主页'),
    name: 'Home',
    component: lazy(() =>
      import('@/features/profiles/pages/Home').then(m => ({
        default: m.Home,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.PersonalHome,
    },
  },
  {
    path: 'goals',
    name: StudentProfileSubRoute.Goals,
    getTitle: () => $t('目标'),
    component: lazy(() =>
      import('@/features/profiles/components/LevelRouteTabs').then(m => ({
        default: m.Level3RouteTabs,
      })),
    ),
    customMeta: {
      pluginPermissions: [
        SchoolModulesEnum.ProfileAcademicGoal,
        SchoolModulesEnum.ProfileGoal,
        SchoolModulesEnum.Direction,
      ],
    },
    subRoutes: [
      ...flatten(getRouteSlots('profileGoalsSubRoutes')),
      {
        path: 'goals',
        name: 'Goals',
        getTitle: () => $t('个人目标'),
        component: lazy(() =>
          import('@/features/goals/pages/students/Goal').then(m => ({
            default: m.Goal,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.GoalGoal,
          pluginPermissions: [SchoolModulesEnum.ProfileGoal],
        },
      },
      {
        path: 'subject-selection',
        name: 'SubjectSelection',
        getTitle: () => $t('选科'),
        component: lazy(() =>
          import(
            '@/features/subject-selection/pages/user/SubjectSelectionDetail'
          ).then(m => ({
            default: m.SubjectSelectionDetail,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.GoalSubjectDirection,
          pluginPermissions: [SchoolModulesEnum.Direction],
        },
      },
    ],
  },
  {
    path: 'grades',
    name: StudentProfileSubRoute.Grades,
    getTitle: () => $t('成绩'),
    component: lazy(() =>
      import('@/features/profiles/components/LevelRouteTabs').then(m => ({
        default: m.Level3RouteTabs,
      })),
    ),
    subRoutes: [
      {
        path: 'grades',
        getTitle: () => $t('成绩'),
        name: 'Grades',
        component: lazy(() =>
          import('@/features/grades/pages/admin/Students/Show/Show').then(
            m => ({
              default: m.Show,
            }),
          ),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.ScoreScore,
          pluginPermissions: [SchoolModulesEnum.ProfileGrade],
        },
      },
      {
        path: 'exams',
        getTitle: () => $t('考试'),
        name: 'Exams',
        component: lazy(() =>
          import('@/features/exams/pages/student/Profile').then(m => ({
            default: m.StudentProfilePublishedExams,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.ScoreExam,
          pluginPermissions: [SchoolModulesEnum.Exam],
        },
      },
      ...flatten(getRouteSlots('profileGradesSubRoutes')),
    ],
  },
  {
    path: 'recent',
    name: StudentProfileSubRoute.Recent,
    getTitle: () => $t('近况'),
    component: lazy(() =>
      import('@/features/profiles/components/LevelRouteTabs').then(m => ({
        default: m.Level3RouteTabs,
      })),
    ),
    customMeta: {
      pluginPermissions: [SchoolModulesEnum.ProfileRecent],
    },
    subRoutes: [
      {
        path: 'schedules',
        getTitle: () => $t('日程'),
        component: lazy(() =>
          import('@/features/profiles/pages/Recent/Schedules').then(m => ({
            default: m.RecentSchedules,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.RecentCalendar,
        },
      },
      {
        path: 'attendances',
        name: 'Attendances',
        getTitle: () => $t('考勤'),
        component: lazy(() =>
          import('@/features/profiles/pages/Recent/Attendances').then(m => ({
            default: m.RecentAttendances,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.RecentAttendance,
        },
      },
      {
        path: 'absences',
        name: 'Absences',
        getTitle: () => $t('请假'),
        component: lazy(() =>
          import('@/features/profiles/pages/Recent/Absences').then(m => ({
            default: m.RecentAbsence,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.RecentAbsence,
        },
      },
      ...flatten(getRouteSlots('profileRecentSubRoutes')),
    ],
  },
  {
    path: 'groups',
    name: StudentProfileSubRoute.Groups,
    getTitle: () => $t('群组'),
    component: lazy(() =>
      import('@/features/profiles/pages/Group').then(m => ({
        default: m.GroupEntry,
      })),
    ),
    customMeta: {
      pluginPermissions: [SchoolModulesEnum.ProfileGroup],
    },
    subRoutes: [
      {
        path: 'course-classes',
        name: 'CourseClasses',
        getTitle: () => $t('课程班'),
        component: lazy(() =>
          import('@/features/profiles/pages/Group/CourseClasses').then(m => ({
            default: m.CourseClasses,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.GroupClass,
        },
      },
      {
        path: 'administrative-classes',
        name: 'AdminClasses',
        getTitle: () => $t('行政班'),
        component: lazy(() =>
          import('@/features/profiles/pages/Group/AdminClasses').then(m => ({
            default: m.AdminClasses,
          })),
        ),
        customMeta: {
          pluginPermissions: [ModuleEnum.AdminClass],
          groupPermission: GroupPermissionTypeEnum.GroupAdminClass,
        },
      },
      ...flatten(getRouteSlots('profileGroupsSubRoutes')),
      {
        name: 'CustomGroups',
        path: 'custom-groups/:pid',
        getTitle: () => $t('群组'),
        component: lazy(() =>
          import('@/features/profiles/pages/Group/CustomGroups').then(m => ({
            default: m.CustomGroups,
          })),
        ),
      },
    ],
  },
  {
    path: 'feedback',
    name: 'Feedback',
    getTitle: () => $t('反馈'),
    component: lazy(() =>
      import('@/features/feedbacks/pages').then(m => ({
        default: m.StudentFeedback,
      })),
    ),
    customMeta: {
      groupPermission: GroupPermissionTypeEnum.RecordFeedback,
      pluginPermissions: [SchoolModulesEnum.Feedback],
    },
  },
  // 深圳中学-定制成绩单
  {
    path: 'sz-grades-reports',
    getTitle: () => $t('报告单'),
    name: StudentProfileSubRoute.SZGradesReports,
    requireSchools: [282, 194],
    component: lazy(() =>
      import('@/features/profiles/components/LevelRouteTabs').then(m => ({
        default: m.Level3RouteTabs,
      })),
    ),

    subRoutes: [
      {
        path: 'exam-report',
        getTitle: () => $t('考试成绩单'),
        name: 'Exam',
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/ExamReport'
          ).then(m => ({
            default: m.ExamReport,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.GradeReportExamReport,
        },
      },
      {
        path: 'semester-report',
        getTitle: () => $t('学期报告单'),
        name: 'Semester',
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SemesterReport'
          ).then(m => ({
            default: m.SemesterReport,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.GradeReportSemesterReport,
        },
      },
      {
        path: 'global-academic-report',
        name: 'Global',
        getTitle: () => $t('总学业报告单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SingleReport'
          ).then(m => ({
            default: m.SingleReport,
          })),
        ),
        customMeta: {
          groupPermission:
            GroupPermissionTypeEnum.GradeReportGlobalAcademicReport,
        },
      },
      {
        path: 'chuguo-report',
        name: 'Chuguo',
        getTitle: () => $t('出国成绩单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/SingleReport'
          ).then(m => ({
            default: m.SingleReport,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.GradeReportChuguoReport,
        },
      },
      {
        path: 'resit-report',
        name: 'Resit',
        getTitle: () => $t('补考成绩单'),
        component: lazy(() =>
          import(
            '@/features/sz-grades-reports/pages/reports/report/ResitReport'
          ).then(m => ({
            default: m.ResitReport,
          })),
        ),
        customMeta: {
          groupPermission: GroupPermissionTypeEnum.GradeReportResitReport,
        },
      },
    ],
  },
  ...flatten(getRouteSlots('profileSubRoutes')),
]
