/**
 * @file chalk classes store
 */

import { ModelReducers } from '@seiue/rematch-core'

import type { MemberSortType } from 'packages/features/tasks'

interface IsTaskExpanded {
  [key: string]: boolean | undefined | null
}

interface TaskMemberSortKeyType {
  [key: string]: string | undefined | null
}

interface ClassesState {
  taskExpandedMap: IsTaskExpanded
  taskMemberSortKeyMap: TaskMemberSortKeyType
}

const state: ClassesState = {
  // 记忆课程班任务列表每个章节收起/展开的状态
  taskExpandedMap: {},
  // 记忆任务详情的排序方式
  taskMemberSortKeyMap: {},
}

/**
 * 获取任务展开状态的 key
 *
 * @param groupId - 组 ID
 * @param outlineId - 大纲 ID
 * @param rid - 反射 ID
 * @returns 任务展开状态的 key
 */
export const getExpandedKey = (
  groupId: number | string,
  outlineId: number,
  rid: number,
) => `group_${groupId}_tasks_outline_${outlineId}_reflection_${rid}`

/**
 * 获取成员排序的 key
 *
 * @param rid - 反射 ID
 * @returns 成员排序的 key
 */
export const getMemberSortKey = (rid: number) =>
  `group_task_reflection_${rid}_sort_key`

const effects = (dispatch: any) => ({
  toggleIsTaskExpanded: (
    param: {
      bizId: number | string
      outlineId: number
      expanded: boolean
    },
    rootState: any,
  ) => {
    const rid = rootState.session?.currentReflection?.id
    if (rid) {
      // 记忆每个章节收起/展开的状态，每个用户的折起操作仅对自己的账号生效
      const expandedKey = getExpandedKey(param.bizId, param.outlineId, rid)
      dispatch.chalkClasses.setIsTaskExpanded({
        [expandedKey]: param.expanded,
      })
    }
  },
  saveTaskMembersSortKey: (
    param: {
      sortKey: MemberSortType
    },
    rootState: any,
  ) => {
    const rid = rootState.session?.currentReflection?.id
    if (rid) {
      // 记忆每个任务的人员的排列方式
      const key = getMemberSortKey(rid)
      dispatch.chalkClasses.setTaskMembersSortKey({
        [key]: param.sortKey,
      })
    }
  },
})

const reducers: ModelReducers<ClassesState> = {
  setIsTaskExpanded(prevState: ClassesState, payload: IsTaskExpanded) {
    const { taskExpandedMap, ...otherState } = prevState
    return {
      ...otherState,
      taskExpandedMap: {
        ...(taskExpandedMap || {}),
        ...payload,
      },
    }
  },
  setTaskMembersSortKey(
    prevState: ClassesState,
    payload: TaskMemberSortKeyType,
  ) {
    const { taskMemberSortKeyMap, ...otherState } = prevState
    return {
      ...otherState,
      taskMemberSortKeyMap: {
        ...(taskMemberSortKeyMap || {}),
        ...payload,
      },
    }
  },
}

export const chalkClasses = {
  state,
  effects,
  reducers,
}
