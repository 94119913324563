/**
 * @file 初始化时的各种 hooks
 */

import { ErrorUI, initGlobalErrorHandlers } from '@seiue/error-handler'
import { Modal, Toast, ZIndex } from '@seiue/ui'
import { findLast, first, isEqual, useOnMount } from '@seiue/util'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { RouteCrumb } from 'packages/components/Page/Breadcrumbs'
import type { EnabledPlugin } from 'packages/feature-utils/plugins'
import { useSchool, useSchoolThemeColors } from 'packages/feature-utils/schools'
import { registerCalendarEvents } from 'packages/features/calendars/utils/register'
import { usePluginsInit as usePluginsInitRaw } from 'packages/features/plugins/utils/hooks'
import { useCheckCurrentSchoolId } from 'packages/features/schools/hooks'
import { registerTodos } from 'packages/features/todos/register'
import { $t } from 'packages/locale'
import {
  addRoutes,
  findMatchRoute,
  getPathAncestors,
  MenuType,
  routes,
  useRouteAuthorization,
} from 'packages/route'
import {
  addMenuProvider,
  extractMenus,
  mergeExtraMenus,
  mergeGroupSubMenus,
  useAllMenus as useAllMenusRaw,
} from 'packages/web-layout/menu-utils'
import { isExtraMenuGroup, MenuItemUnion } from 'packages/web-layout/types'

import { useSiderHeaderMenus } from '@/features/home/menus'
import { initShares } from '@/features/shares'
import { useLogoInfo } from '@/features/system-settings/utils/data'
import { registerWidgets } from '@/features/widgets/register'
import { registerChalkWorkflowRenders } from '@/features/workflows/register'
import { useDispatch } from '@/store'

import { initFeatures } from '../features'
import { initPlugins } from '../plugins'

/**
 * 判断是否为无代码平台应用
 *
 * @param modules - 模块名称
 * @returns boolean
 */
export const isNoCodeModule = (modules: string[]) => {
  return isEqual(modules, ['nocode'])
}

/**
 * 获取所有菜单
 *
 * @returns menus
 */
export const useAllMenus = () => {
  const authRoute = useRouteAuthorization()

  const newMenus = useAllMenusRaw()

  const _layoutMenus = useMemo(() => {
    // create menu data
    const routeMenus = extractMenus(routes, authRoute)

    const mergedMenus = mergeExtraMenus(routeMenus, [...newMenus])

    return mergeGroupSubMenus(mergedMenus)
  }, [authRoute, newMenus])

  const siderHeaderMenus = useSiderHeaderMenus()

  // 不参与排序
  const menus: MenuItemUnion[] = siderHeaderMenus.map((m: any, index) => {
    return index === siderHeaderMenus.length - 1
      ? { ...m, appendDivider: true }
      : m
  })

  const flattenMenus = [
    ...menus,
    ..._layoutMenus.default,
    ..._layoutMenus.apps,
    ..._layoutMenus.admin,
    ..._layoutMenus.adminApps,
  ] as MenuItemUnion[]

  return {
    flattenMenus,
    layoutMenus: {
      ..._layoutMenus,
      default: [..._layoutMenus.default, ...siderHeaderMenus],
    },
  }
}

/**
 * 获取侧边栏要展示的菜单
 *
 * @returns menus
 */
export const useSiderMenus = () => {
  const location = useLocation()
  const { pathname } = location
  const pathAncestors = getPathAncestors(pathname)

  const routeArr = pathAncestors.reduce<RouteCrumb[]>((res, cur) => {
    const route = findMatchRoute(cur, routes)
    return route
      ? [
          ...res,
          {
            ...route,
            realPath: cur,
          },
        ]
      : res
  }, [])

  const { flattenMenus } = useAllMenus()

  const { module: menuModules, realPath: menuRealPath = '/' } =
    findLast(routeArr, route => !!route.module) || {}

  return React.useMemo(() => {
    // 未匹配到 module 则走默认菜单逻辑
    if (!menuModules) {
      return {
        modules: [MenuType.Default],
        menus: flattenMenus.filter(
          item =>
            !item.type ||
            ![MenuType.Apps, MenuType.Admin, MenuType.AdminApps].includes(
              item.type,
            ),
        ),
      }
    }

    if (isNoCodeModule(menuModules)) {
      // 无代码平台应用动态路由处理
      const isAdmin = menuRealPath.startsWith('/admin')
      const parts = menuRealPath.split('/')
      const appName = parts[parts.indexOf('nocode') + 1]

      const findInMenus = (
        menus: MenuItemUnion[],
      ): MenuItemUnion | undefined => {
        for (const cur of menus) {
          const shouldVisible =
            (isAdmin && cur.type !== MenuType.Apps) ||
            (!isAdmin && cur.type === MenuType.Apps)

          if (cur.name === appName && shouldVisible) {
            return cur
          }

          if (isExtraMenuGroup(cur)) {
            const found = findInMenus(cur.subMenus)
            if (found) {
              return found
            }
          }
        }

        return undefined
      }

      return {
        modules: menuModules,
        menus: findInMenus(flattenMenus),
      }
    }

    // 根据 moduleName 匹配到目标菜单
    function findMenuByModuleName(
      moduleNames: string[],
      menus: MenuItemUnion[],
    ): MenuItemUnion | undefined {
      const _moduleName = first(moduleNames)

      for (const cur of menus) {
        /**
         * 匹配目标菜单组的条件
         * 1. 菜单定义为「菜单组」
         * 2. 菜单 name 字段等于路由定义的 module 字段
         * 3. 菜单组未定义 path 字段或菜单组定义了 path 字段（通常多插件会定义 path）
         */
        if (
          isExtraMenuGroup(cur) &&
          cur.name === _moduleName &&
          (!cur.path || cur.path === menuRealPath)
        ) {
          moduleNames.shift()

          if (!moduleNames.length) {
            return cur
          }

          return findMenuByModuleName(moduleNames, cur.subMenus)
        }
      }

      return undefined
    }

    return {
      modules: menuModules,
      menus: menuModules.length
        ? findMenuByModuleName([...menuModules], flattenMenus)
        : undefined,
    }
  }, [flattenMenus, menuModules, menuRealPath])
}

/**
 * 注册全局错误处理
 */
export const useRegisterErrorHandler = () => {
  const dispatch = useDispatch()

  useOnMount(() => {
    initGlobalErrorHandlers(meta => {
      if (meta.action === ErrorUI.Report) {
        return Modal.results({
          type: 'error',
          title: meta.title ?? $t('操作失败'),
          errors: meta.content,
          zIndex: ZIndex.Toast,
        })
      }

      if (meta.action === ErrorUI.Alert) {
        return Modal.warning({
          title: meta.title,
          content: meta.content,
          okText: $t('我知道了'),
          onOk: meta.onConfirm,
        })
      }

      if (meta.action === ErrorUI.AlertThenLogout) {
        return Modal.warning({
          title: meta.title,
          content: meta.content,
          okText: $t('好的'),
          onOk: () => dispatch.session.destroy(),
        })
      }

      if (meta.action === ErrorUI.Toast) {
        return Toast.warning(meta.title)
      }

      return null
    })
  })
}

/**
 * 初始化插件。获取当前用户有权限使用的所有插件，并对 features、plugins 初始化
 *
 * @param sessionCreated - Session 是否已创建
 * @returns boolean 插件是否初始化完成
 */
export const useInitFeaturesAndPlugins = (sessionCreated: boolean) =>
  usePluginsInitRaw(sessionCreated, enabledPlugins => {
    // 先初始化插件，因为 feature 中会有模块使用到插件
    initPlugins(
      {
        addRoutes,
        addMenuProvider,
        registerTodos,
        registerCalendarEvents,
        registerWorkflowRenders: registerChalkWorkflowRenders,
        registerWidgets,
      },
      enabledPlugins,
    )

    const hasEnabledPlugin = (filter: (plugin: EnabledPlugin) => boolean) =>
      !!enabledPlugins.find(filter)

    initFeatures({
      addRoutes,
      addMenuProvider,
      registerTodos,
      registerCalendarEvents,
      registerWidgets,
      registerWorkflowRenders: registerChalkWorkflowRenders,
      hasEnabledPlugin,
    })
  })

/**
 * 在公开分享模式下 (分享链接 + session 创建失败) 只加载 shares feature
 *
 * @param shouldInit - 是否应该初始化
 * @returns 是否已经初始化
 */
export const useInitSharesOnly = (shouldInit: boolean) => {
  const [inited, setInited] = useState(false)

  useEffect(() => {
    if (shouldInit) {
      initShares({
        addRoutes,
        addMenuProvider,
        registerTodos,
        registerCalendarEvents,
        registerWidgets,
        registerWorkflowRenders: registerChalkWorkflowRenders,
      })

      setInited(true)
    }
  }, [shouldInit])

  return inited
}

/**
 * 获取 Layout 中 logo 的相关数据
 *
 * @param presentedSchoolId - 优先展示的学校 logo
 * @returns logo 数据
 */
export const useLogoData = (
  /**
   * 优先展示的学校 logo（如公开分享页优先显示地址栏中 schoolId 的学校）
   * 如果指定则显示该 id 对应学校 logo
   * 否则使用 session 中的 currentSchool
   */
  presentedSchoolId?: number | null,
) => {
  const dispatch = useDispatch()
  const school = useSchool(presentedSchoolId)

  useEffect(() => {
    if (school) {
      dispatch.session.setCurrentSchool(school)
    }
  }, [school, dispatch.session])

  return useLogoInfo(school)
}

/**
 * 读取当前学校的主题色配置
 *
 * @returns 主题色配置
 */
export const useChalkThemeColors = useSchoolThemeColors

/**
 * 是否显示应用中心入口
 *
 * @returns boolean
 */
export const useShowAppCenter = () => {
  if (useCheckCurrentSchoolId(391)) {
    // FDFZ OEM 不显示应用中心
    return false
  }

  return true
}
